import React, {useState, useEffect } from 'react';
import style from './landing.module.css';
import Banner from "./Banner/banner";
import Catalog from "./catalog/catalog";
import WhatWeDo from "./whatWeDo/whatWeDo";
import Features from "./Features/features";
import WhoWeAre from "./whoWeAre/whoWeAre";
import UserReview from "./userReviews/userReview";
import Footer from "./Footer/footer";
const Landing = ()=>{
    //
    // const [loaded, setLoaded] = useState(false);
    //
    // useEffect(() => {
    //     // Set loaded to true when component mounts
    //     setLoaded(true);
    // }, []);
    //
    // useEffect(() => {
    //     // Apply the fade-in class after all resources have loaded
    //     if (loaded) {
    //         const content = document.getElementById('content');
    //         content.classList.remove('fade-out');
    //         content.classList.add('fade-in');
    //     }
    // }, [loaded]);
    return (
        <div>
            <div id="content" className={`${style.landing_page_section}`}>
            <Banner/>
            <Catalog/>
            <WhatWeDo/>
            <Features/>
            <WhoWeAre/>
            <UserReview/>
            <Footer/>
            </div>
        </div>
    );

}
export default  Landing;